import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ViewRoom from "./pages/ViewRoom";
import BillingInvoice from "./pages/BillingInvoice";
import ViewBill from "./pages/ViewBill";
import EditBill from "./pages/EditBill";
import NewRegister from "./pages/NewRegister";
import ViewRegister from "./pages/ViewRegister";
import EditRegister from "./pages/EditRegister";
import NewRoom from "./pages/NewRoom";
import RentBill from "./pages/RentBill";
import ViewBeds from "./components/viewroomcomp/ViewBeds";
import EditRoomPage from "./pages/editroomscreen/EditRoomPage";
import LoginRegister from "./pages/loginregisterscreen/LoginRegister";
import ViewCustomerRegister from "./pages/viewregisterscreen/ViewCustomerRegister";
import Login from "./pages/Login";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      {/* <h2>HWE</h2> */}
      {/* {localStorage.getItem("login") !== "Login successful" ? ( */}
      <>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/viewRoom" element={<ViewRoom />} />
          <Route path="/billingInvoice" element={<BillingInvoice />} />
          <Route path="/viewBill" element={<ViewBill />} />
          <Route path="/editBill" element={<EditBill />} /> */}
          <Route path="/newRegister" element={<NewRegister />} />
          {/* <Route path="/viewRegister" element={<ViewRegister />} />
          <Route path="/editRegister" element={<EditRegister />} />
          <Route path="/newRoom" element={<NewRoom />} />
          <Route path="/newBill" element={<RentBill />} />
          <Route path="/viewBeds" element={<ViewBeds />} />
          <Route path="/editroom" element={<EditRoomPage />} />
          <Route path="/loginRegister" element={<LoginRegister />} /> */}
        </Routes>
      </>

      {/* // ) : (
      //   <>
      //     <Routes>
      //             //       <Route
      //         path="/viewCustomerRegister"
      //         element={<ViewCustomerRegister />}
      //       />
      //     </Routes>
      //   </>
      // )} */}
    </QueryClientProvider>
  </BrowserRouter>
);
