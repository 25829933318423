import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import BillingAddInputField from "../components/BillingAddInputField";
import { useNavigate, Link } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";

const Login = () => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [type, setType] = useState("password");
  const [isShow, setIsShow] = useState(true);

  const { mutate, isError, isLoading, status } = useMutation(
    (postdata) =>
      axios.post(`${process.env.REACT_APP_HOST}/registers/login`, postdata),
    {
      onSuccess: (obj) => {
        console.log("obj", obj);
        const branchNames =
          obj &&
          obj?.data &&
          obj?.data?.registers?.map((data, index) => {
            return data?.shopName;
          });
        if (Array.isArray(branchNames)) {
          localStorage.setItem("branchNames", JSON.stringify(branchNames));
        }
        // const userObject = {
        //   accountName: obj && obj?.data && obj?.data?.register?.accountName,
        //   accountNumber: obj && obj?.data && obj?.data?.register?.accountNumber,
        //   accountType: obj && obj?.data && obj?.data?.register?.accountType,
        //   bankName: obj && obj?.data && obj?.data?.register?.bankName,
        //   branch: obj && obj?.data && obj?.data?.register?.branch,
        //   ifsccode: obj && obj?.data && obj?.data?.register?.ifscCode,
        //   registerNumber:
        //     obj && obj?.data && obj?.data?.register?.registerNumber,
        //   shopName: obj && obj?.data && obj?.data?.register?.shopName,
        //   companyAddress:
        //     obj && obj?.data && obj?.data?.register?.companyAddress,
        //   companyLocation:
        //     obj && obj?.data && obj?.data?.register?.companyLocation,
        //   companyPincode:
        //     obj && obj?.data && obj?.data?.register?.companyPincode,
        //   role: obj && obj?.data && obj?.data?.register?.role,
        //   customerName: obj && obj?.data && obj?.data?.register?.customerName,
        //   emailId: obj && obj?.data && obj?.data?.register?.emailId,
        //   customerPhno: obj && obj?.data && obj?.data?.register?.customerPhno,
        //   ifscCode: obj && obj?.data && obj?.data?.register?.ifscCode,
        //   id: obj && obj?.data && obj?.data?.register?.id,
        // };
        const regidetails = obj && obj?.data && obj?.data?.registers;
        const users = JSON.stringify(regidetails);
        localStorage.setItem("registers", users);
        // const userString = JSON.stringify(userObject);
        localStorage.setItem("login", obj?.data?.message || "");
        // localStorage.setItem("billInfo", userString);
        // navigate("/viewRoom");
        navigate("/newRegister");
        window.location.reload();
      },
      onError: (error) => {
        setError(error);
        console.error("Mutation failed:", error);
      },
    }
  );
  useEffect(() => {
    if (localStorage && localStorage.getItem("login") === "Login successful") {
      // navigate("/viewRoom");
      navigate("/newRegister");
    }
  }, []);

  const onSubmit = (data) => {
    console.log("Data", data);
    mutate(data);
  };
  return (
    <>
      <section className="mainSection p-0 m-0">
        <div className="container">
          <div className="row  py-3 m-0">
            <div className="col-lg-12">
              <p className="login-head"> 2k Inventory - Hostel Billing</p>
            </div>
          </div>
          <div className="row mainBlock p-0 m-0">
            <div className="col-lg-6 img_blocks">
              <img
                src="/assets/images/loginbackground.webp"
                className="img-fluid login-image"
                alt="Login background"
                width="1920"
                height="1080"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6 img_blocks">
              <div className="row p-0 m-0">
                <div className="col-lg-12 loginBlock">
                  <h6 className="loginHeading">Login</h6>
                  <h6 className="loginSubHeading">Welcome Back</h6>
                  <form
                    className="loginFormBlock"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="login_input_container">
                      <div className="image_block">
                        <img
                          src="/assets/images/email.webp"
                          alt="no-image"
                          className="image_icon"
                          loading="lazy"
                        />
                      </div>
                      <span style={{ fontWeight: "bolder", fontSize: "17px" }}>
                        |
                      </span>
                      <div className="billing-login-input-block">
                        <Controller
                          control={control}
                          name="username"
                          render={(field) => (
                            <BillingAddInputField
                              {...field}
                              placeholder="Email address"
                              type="email"
                              login_input_field="login_input_field"
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="login_input_password_container p-0 m-0">
                      <div className="login-password-input-container">
                        <div className="image_block">
                          <img
                            src="/assets/images/password.webp"
                            alt="no-image"
                            className="image_icon"
                            loading="lazy"
                          />
                        </div>
                        <span
                          style={{ fontWeight: "bolder", fontSize: "17px" }}
                        >
                          |
                        </span>
                        <div className="billing-login-input-block">
                          <Controller
                            control={control}
                            name="password"
                            render={(field) => (
                              <BillingAddInputField
                                {...field}
                                placeholder="Password"
                                type={`${!isShow ? "text" : "password"}`}
                                login_input_field="login_input_field"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                      <div
                        className="passwordBlock"
                        onClick={() => {
                          setIsShow(!isShow);
                        }}
                      >
                        {!isShow ? (
                          <img
                            src="/assets/images/visibility_on_white.webp"
                            className="image_icon"
                            alt="no-image"
                            loading="lazy"
                          />
                        ) : (
                          <img
                            src="/assets/images/visibility_off_white.webp"
                            className="image_icon"
                            alt="no-image"
                            loading="lazy"
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      {isError && (
                        <p
                          style={{ color: "red", fontWeight: "500" }}
                          className="p-0 m-0"
                        >
                          {error &&
                            error?.response &&
                            error?.response?.data &&
                            error?.response?.data?.message}
                        </p>
                      )}
                    </div>
                    <div className="loginBtnBlock">
                      <button type="submit" className="loginBtn">
                        {isLoading ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
